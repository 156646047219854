import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    fixed: {
      [theme.breakpoints.up('lg')]: {
        position: 'fixed',
        top: '25px',
      },
    },
    locked: {
      [theme.breakpoints.up('lg')]: {
        position: 'absolute',
        overflow: 'hidden',
      },
    },
  }),

  { name: 'ds-app-CallCenterBoxControl' },
);
