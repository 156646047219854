import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    root: {
      minHeight: 'calc(100% - 352px)',
    },
    rootContainer: {
      paddingTop: theme.pagePadding.contentPaddingTop,
      paddingBottom: theme.pagePadding.contentPaddingBottom,
      [theme.breakpoints.down('lg')]: {
        paddingTop: '0px',
      },
    },
    mobileAppBarDisabled: {
      paddingTop: theme.pagePadding.contentPaddingTopMobile,
      paddingBottom: theme.pagePadding.contentPaddingBottomMobile,
    },
    callCenterBox: {
      minHeight: '210px',
    },
  }),
  { name: 'ds-app-Page' },
);
