import {
  DigitalSalesApi as CommonDigitalSalesApi,
  DocItemMetaMap as CommonDocItemMetaMap,
  FeatureManagement,
  FeatureUserInfo,
  FlowProgress,
  FlowProgressBoundary,
  FlowProgressStatus as CommonFlowProgressStatus,
  ModuleType,
  PageConfig as CommonPageConfig,
  PageEnabler as CommonPageEnabler,
  PageEnablerMap as CommonPageEnablerMap,
  ProductCode,
  ProductExternalComponent as CommonProductExternalComponent,
  ProductQuoteId as CommonProductQuoteId,
  SectionExternalComponentMap as CommonSectionExternalComponentMap,
} from '@amfament/digitalsales-lib-common-base/coreService';
import type { History } from 'history';

export type { ResourceConfig } from '@amfament/digitalsales-lib-common-base/coreService';
export { FlowProgress, ModuleType, ProductCode };
export type { FeatureManagement, FlowProgressBoundary };

export enum FlowType {
  Quote = 'quoteFlow',
  Bind = 'bindFlow',
}

declare module 'piral-core/lib/types/custom' {
  type DocItemMetaMap = CommonDocItemMetaMap;
  interface ProductDocItemMetaMap {
    [productCode: string]: DocItemMetaMap;
  }
  interface PageItemMetaMap {
    [route: string]: ProductDocItemMetaMap;
  }

  type SectionExternalComponentMap = CommonSectionExternalComponentMap;
  type ProductExternalComponent = CommonProductExternalComponent;
  interface SectionExternalComponentsMap {
    [sectionName: string]: ProductExternalComponent[];
  }
  interface PageExternalComponentMap {
    [route: string]: SectionExternalComponentsMap;
  }

  type PageEnabler = CommonPageEnabler;
  type PageEnablerMap = CommonPageEnablerMap;

  type PageConfig = CommonPageConfig;

  interface DigitalSalesApi extends CommonDigitalSalesApi {
    /**
     * Configures the pages/routes of a module and associates to a product if appliciable.
     * @param type - Name of module.
     * @param pages - Pages in module.
     * @param productCodes - Specific products associated to this module and provided pages.
     */
    configureModule(
      type: ModuleType,
      pages: PageConfig[],
      productCodes?: ProductCode[],
    ): void;
    /**
     * Registers document item meta to a page and product code.
     * @param route - The page route to associate to.
     * @param productCode - The product code the meta is associated with.
     * @param docItemMetaMap - The document item meta.
     */
    registerDocItemMetaMap(
      route: string,
      productCode: ProductCode,
      docItemMetaMap: DocItemMetaMap,
    ): void;
    /**
     * Registers external components to a page and product code.
     * @param route - The page route to associate to.
     * @param productCode - The product code the component is associated with.
     * @param sectionComponentMap - The page section external component map.
     */
    registerExternalComponents(
      route: string,
      productCode: ProductCode,
      sectionComponentMap: SectionExternalComponentMap,
    ): void;
    /**
     * Registers a page enabler to a map of enablers.
     * @param route - The page route to enable/disable.
     * @param enabler - The enabler predicate.
     * @param productCode - The product code the enabler is associated with. Only active products are used at runtime.
     */
    registerPageEnabler(
      route: string,
      enabler: PageEnabler,
      productCode?: ProductCode,
    ): void;
  }

  interface PiletCustomApi {
    digitalsales: DigitalSalesApi;
  }

  interface PiralCustomActions
    extends Omit<
      DigitalSalesApi,
      'getResourceConfig' | 'navigateToPage' | 'setFeatureUserInfo'
    > {
    /**
     * Navigates to a page in the app, appends the packageId if available and maintains previous route tracking state.
     * @param route - The route to navigate to.
     * @param history - History object used to navigate.
     * @param fromPrecedingPage - Indicates that it's routing from a previous page.
     * @param otherParams - The optional other params to append to the url.
     * @param packageId - the packageId to navigate with.
     */
    navigateToPage(
      route: string,
      history: History,
      fromPrecedingPage?: boolean,
      otherParams?: URLSearchParams,
      packageId?: string,
    ): void;
    /**
     * Configures the application modules and pages using the feature manager decisions.
     * @param userInfo - The FeatureManager client instance.
     * @param Key - Key for the feature resource.
     */
    configureAppFeatures(userInfo: FeatureUserInfo, key: string): void;
  }

  interface LoadingStateProcess {
    processName: string;
    message: React.ReactNode;
  }
  type ProductQuoteId = CommonProductQuoteId;

  /**
   * A page in a module.
   */
  interface Page extends PageConfig {
    /**
     * Pre-calculated ordinal based on productCodes and followProductType attributes.
     * Used for sorting.
     */
    pageOrdinal: number;
    /**
     * Indicates that a page is active and can be used.
     */
    active: boolean;
    /**
     * Indicates that an active page is enabled and being used.
     */
    enabled: boolean;
  }
  interface Module {
    type: ModuleType;
    label: string;
    pages: Page[];
    routes: string[];
    routesWithoutHidden: string[];
    active: boolean;
    featureManagement?: FeatureManagement;
  }
  interface Flow {
    [moduleName: string]: Module;
  }
  interface QuoteFlow extends Flow {
    Welcome: Module;
    Home: Module;
    Auto: Module;
    Cycle: Module;
    YourDetails: Module;
    Quote: Module;
  }
  interface BindFlow extends Flow {
    AgentsAndLenders: Module;
    FinalDetails: Module;
    BindYourPolicy: Module;
    Payment: Module;
    PolicyConfirmation: Module;
  }

  interface FeatureMeta {
    userInfo: FeatureUserInfo;
    featureResourceKey: string;
  }
  type FlowProgressStatus = CommonFlowProgressStatus;

  interface DigitalSalesState {
    displaySaveForLater: boolean;
    analyticData: Record<string, any>;
    displayCallCenterBox: boolean;
    callCenterBoxStartPosition: string;
    lockCallCenterBox: boolean;
    displayCreditBasedScoringLink: boolean;
    displayHeaderFooter: boolean;
    displayProgressStepper: boolean;
    lockProgressStepper: boolean;
    fullPageDisplay: boolean;
    featureMeta: FeatureMeta;
    headerTitle: React.ReactNode;
    loadingState: LoadingStateProcess[];
    pageEnablerMap: PageEnablerMap;
    pageItemMetaMap: PageItemMetaMap;
    pageExternalComponentMap: PageExternalComponentMap;
    productQuoteIds: ProductQuoteId[];
    resourceData: Record<string, any>;
    quoteFlow: Flow;
    bindFlow: Flow;
    currentFlow: FlowType;
    currentFlowProgressStatus: FlowProgressStatus | null;
    productCodes: ProductCode[];
  }
  interface PiralCustomState {
    digitalsales: DigitalSalesState;
  }
}
