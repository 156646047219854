import {
  FeatureManagement,
  getProductMetas,
} from '@amfament/digitalsales-lib-common-base/coreService';
import { formatSentenceFromArray } from '@amfament/digitalsales-lib-common-base/utils';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import { useGlobalState } from 'piral-core';
import { useMemo } from 'react';
import { FlowType } from './types';
import { getFeatureState } from './actions_configureApp';

export const useDisplayHeaderFooter = () =>
  useGlobalState((s) => s.digitalsales.displayHeaderFooter);

export const useDisplaySaveForLater = () =>
  useGlobalState((s) => s.digitalsales.displaySaveForLater);

export const useHeaderTitle = () => {
  const productCodes = useGlobalState((s) => s.digitalsales.productCodes);
  const currentFlow = useGlobalState((s) => s.digitalsales.currentFlow);
  if (isEmpty(productCodes)) {
    // resume screens
    return 'Continue Quote';
  }
  const productLabels = getProductMetas(productCodes).map((meta) => meta.label);
  return currentFlow === FlowType.Quote
    ? `Get Your ${formatSentenceFromArray(productLabels)} Quote`
    : `Finalize Your ${formatSentenceFromArray(productLabels)} Coverage`;
};

export const useProductQuoteIds = () =>
  useGlobalState((s) => s.digitalsales.productQuoteIds);

export const useDisplayProgressStepper = () =>
  useGlobalState((s) => s.digitalsales.displayProgressStepper);

export const useLockProgressStepper = () =>
  useGlobalState((s) => s.digitalsales.lockProgressStepper);

export const useFullPageDisplay = () =>
  useGlobalState((s) => s.digitalsales.fullPageDisplay);

export const useCurrentFlowProgressStatus = () =>
  useGlobalState((s) => s.digitalsales.currentFlowProgressStatus);

export const useProgressStepperFlowModules = () => {
  const currentFlow = useGlobalState((s) => s.digitalsales.currentFlow);
  const quoteFlow = useGlobalState((s) => s.digitalsales.quoteFlow);
  const bindFlow = useGlobalState((s) => s.digitalsales.bindFlow);
  return useMemo(() => {
    const flow = currentFlow === FlowType.Quote ? quoteFlow : bindFlow;
    return Object.values(flow).filter((module) => module.active);
  }, [bindFlow, currentFlow, quoteFlow]);
};

export const useDisplayCallCenterBox = () =>
  useGlobalState((s) => s.digitalsales.displayCallCenterBox);

export const useCallCenterBoxStartPosition = () =>
  useGlobalState((s) => s.digitalsales.callCenterBoxStartPosition);

export const useLockCallCenterBox = () =>
  useGlobalState((s) => s.digitalsales.lockCallCenterBox);

export const useDisplayCreditBasedScoringLink = () =>
  useGlobalState((s) => s.digitalsales.displayCreditBasedScoringLink);

export const useLoadingStateProcess = () => {
  const loadingState = useGlobalState((s) => s.digitalsales.loadingState);
  return head(loadingState);
};

export const useProductCodes = () =>
  useGlobalState((s) => s.digitalsales.productCodes);

export const useFeature = (featureManagement: FeatureManagement) => {
  const { featureResourceKey = '' } =
    useGlobalState((state) => state.digitalsales.featureMeta) || {};

  const features = useGlobalState((state) => {
    const featureData = state.digitalsales.resourceData[featureResourceKey];

    return featureData?.features;
  });

  return getFeatureState(features, featureManagement, false);
};
