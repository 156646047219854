import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    appBarRoot: {
      minHeight: '64px',
      overflow: 'hidden',
      background: theme.palette.background.altNeutral,
      borderBottom: `1px solid ${theme.palette.divider}`,
      width: '100%',
    },
    root: {
      minHeight: '64px',
      background: 'transparent',
      boxShadow: 'none',
      '@media print': {
        display: 'none',
      },
      zIndex: 3,
      top: 0,
      width: '100%',
    },
    currentStep: {
      color: theme.palette.primary.main,
      paddingBottom: '12px',
      overflowWrap: 'break-word',
      maxWidth: '90%',
    },
    pageName: {
      fontWeight: theme.typography.fontWeightRegular,
    },
  }),
  { name: 'ds-app-MobileAppBarNew' },
);
