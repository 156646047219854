import ThemeProvider from '@amfament/digitalsales-lib-coreuicomps/ThemeProvider';
import { AdapterDayjs as DateAdapter } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import type { ComponentsState, ErrorComponentsState } from 'piral-core';
import { CookiesProvider } from 'react-cookie';
import { Link } from 'react-router-dom';
import type { ResourceConfig } from '../app/types';
import HardStopPage from './HardStop';
import Layout from './Layout';
import LoadingSpinner from './LoadingSpinner';

declare let CONFIG: ResourceConfig;
const webAssets = CONFIG.webassets.baseURL;

export const errors: Partial<ErrorComponentsState> = {
  not_found: () => (
    <div>
      <p className="error">
        Could not find the requested page. Are you sure it exists?
      </p>
      <p>
        Go back <Link to="/">to the dashboard</Link>.
      </p>
    </div>
  ),
};
export const layout: Partial<ComponentsState> = {
  ErrorInfo: () => <HardStopPage />,
  Layout: ({ children }) => {
    return (
      <ThemeProvider classNamePrefix="application" useDefaultTheme>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <CookiesProvider>
            <Layout>{children}</Layout>
          </CookiesProvider>
        </LocalizationProvider>
      </ThemeProvider>
    );
  },
  LoadingIndicator: () => (
    <ThemeProvider classNamePrefix="application" useDefaultTheme>
      <LoadingSpinner isLoading />
    </ThemeProvider>
  ),
};
