import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    stepIcon: {
      marginLeft: '15px',
      marginRight: '15px',
      height: '36px',
      marginBottom: '3px',
      zIndex: 14,
      background: theme.palette.grey[50],
    },
  }),
  { name: 'ds-app-StepIcon' },
);
