import type { Theme } from '@mui/material';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  useDisplayCallCenterBox,
  useFullPageDisplay,
} from '../../app/ContextAPI';
import CallCenterBoxControl from '../CallCenterBoxControl';
import ProgressStepper from '../ProgressStepper';
import useStyles from './useStyles';

const Page: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const classes = useStyles();
  const fullPageDisplay = useFullPageDisplay();
  const displayCallCenter = useDisplayCallCenterBox();
  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('lg'),
  );
  return (
    <Grid
      container
      item
      className={
        fullPageDisplay && isMobile
          ? classes.mobileAppBarDisabled
          : classes.rootContainer
      }
    >
      {!fullPageDisplay && (
        <Grid container item lg={2} xs={12}>
          <ProgressStepper />
        </Grid>
      )}
      <Grid
        container
        className={classes.root}
        item
        lg={fullPageDisplay ? 12 : 8}
        xs={12}
      >
        {children}
      </Grid>
      {!fullPageDisplay && (
        <Grid
          container
          item
          lg={2}
          xs={12}
          className={displayCallCenter ? classes.callCenterBox : undefined}
        >
          <CallCenterBoxControl />
        </Grid>
      )}
    </Grid>
  );
};

export default Page;
