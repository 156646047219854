import CallCenterBox from '@amfament/digitalsales-lib-common/CallCenterBox';
import type { Theme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/styles/useTheme';
import cn from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import {
  useCallCenterBoxStartPosition,
  useDisplayCallCenterBox,
  useLockCallCenterBox,
} from '../../app/ContextAPI';
import useStyles from './useStyles';

const CallCenterBoxControl: React.FC = () => {
  const displayCallCenterBox = useDisplayCallCenterBox();
  const lockCallCenterBox = useLockCallCenterBox();
  const classes = useStyles();

  const [shouldFix, setShouldFix] = useState(false);
  const customTheme = useTheme();
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setShouldFix(window.scrollY > customTheme.sidePanel.scrollPoint);
    });
  }, [customTheme.sidePanel.scrollPoint]);

  const callCenterBoxStartPosition = useCallCenterBoxStartPosition();
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'));
  const rootOverride = useMemo(
    () =>
      isDesktop && callCenterBoxStartPosition
        ? {
            top: callCenterBoxStartPosition,
          }
        : undefined,
    [callCenterBoxStartPosition, isDesktop],
  );

  return displayCallCenterBox ? (
    <CallCenterBox
      className={cn({
        [classes.locked]: lockCallCenterBox || !shouldFix,
        [classes.fixed]: !lockCallCenterBox && shouldFix,
      })}
      style={rootOverride}
    />
  ) : null;
};

export default CallCenterBoxControl;
