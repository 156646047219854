import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(
  (theme) => ({
    root: {
      [theme.breakpoints.down('md')]: {
        padding: '20px',
      },
    },
    image: {
      width: '90%',
      height: '732px',
      marginTop: '72px',
      [theme.breakpoints.down('lg')]: {
        width: '75%',
        position: 'relative',
        left: '10%',
        objectFit: 'cover',
        height: '296px',
        margin: 'auto',
        marginTop: '15px',
      },
    },
    titleContainer: {
      paddingTop: '15px',
      paddingLeft: '15px',
      paddingRight: '15px',
    },
    title: {
      textTransform: 'capitalize',
      [theme.breakpoints.up('lg')]: {
        marginTop: '90px',
      },
    },
    subtitle: {
      marginTop: '25px',
      marginBottom: '48px',
      [theme.breakpoints.down('md')]: {
        marginTop: '10px',
        marginBottom: '15px',
      },
    },
    footerSeparation: {
      marginTop: '20px',
    },
  }),
  { name: 'ds-app-HardStop' },
);
