import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Step from '@mui/material/Step';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import CoreStepper from '@mui/material/Stepper';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import cn from 'classnames';
import { useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import {
  createCanNavigate,
  getRefinedRoute,
  useCurrentFlowProgressStatus,
  useProgressStepperFlowModules,
} from '../../../app/ContextAPI';
import StepIcon from './StepIcon';
import useStyles from './useStyles';

const DottedConnector = withStyles((theme) =>
  createStyles({
    active: {
      [`& .${stepConnectorClasses.line}`]: {},
    },
    completed: {
      [`& .${stepConnectorClasses.line}`]: {},
    },
    vertical: {
      paddingBottom: '0px',
      marginLeft: '30px',
    },
    line: {
      borderLeftWidth: '2px',
      borderLeftColor: theme.palette.primary.main,
      borderLeftStyle: 'dashed',
    },
  }),
)(StepConnector);

const Stepper: React.FC = () => {
  const flowModules = useProgressStepperFlowModules();
  const location = useLocation();
  const activeModule = flowModules.findIndex((module) =>
    module.routes.includes(location.pathname),
  );
  const currentFlowProgressStatus = useCurrentFlowProgressStatus();
  const canNavigateToPage = useMemo(
    () => createCanNavigate(currentFlowProgressStatus),
    [currentFlowProgressStatus],
  );
  const classes = useStyles();

  const steps = useMemo(() => {
    // Boolean used to determine if the page can be navigated to.
    let canNavigate = true;
    /**
     * @method createPageLink: Creates the "step" colored label used in the stepper component
     * @param isCurrentPage: Boolean value to check if this link is to the current page.
     * @param label: The label for the stepper.
     * @param route: The route that the stepper will go to.
     * @param additionalClasses: Any additional CSS classes to be applied.
     * @param module: boolean value to determine if the page link is being created for a module.
     * @returns: A nav link or label based on if the link is active and can be navigated to.
     */
    const createPageLink = (
      isCurrentPage: boolean,
      label: string,
      route: string,
      additionalClasses,
      module?: boolean,
    ) => {
      const navigableStepStyle = module
        ? classes.stepNavigableLink
        : classes.stepPageNavigableLink;
      return isCurrentPage ? (
        <span
          className={cn(
            classes.stepPageLink,
            navigableStepStyle,
            additionalClasses,
          )}
        >
          {label}
        </span>
      ) : (
        <NavLink
          to={getRefinedRoute(route)}
          className={cn(classes.stepPageLink, navigableStepStyle)}
          activeClassName={additionalClasses}
          replace
        >
          {label}
        </NavLink>
      );
    };
    return flowModules
      .map((module, index) => {
        // Filters the available pages to ensure they are enabled in the flow and not set to hidden.
        const displayablePages = module.pages.filter(
          (page) => page.enabled && !page.hidden,
        );
        if (displayablePages.length === 0) {
          return undefined;
        }
        // Check if it is a single page, the first page, and if the module is the current page.
        const singlePage = displayablePages.length === 1;
        const firstPage = displayablePages[0];
        const isCurrentModulePage = firstPage.route === location.pathname;

        // Set the page link for the module, if it is the current page, then make it a label, if it is not make it a link.
        const modulePageLink = createPageLink(
          isCurrentModulePage,
          module.label,
          firstPage.route,
          singlePage ? classes.stepPageLinkActive : undefined,
          true,
        );

        // Create the step to be displayed under the module name in the stepper.
        const step = (
          <Step key={module.label}>
            <StepLabel
              StepIconComponent={StepIcon}
              className={classes.stepPage}
            >
              {/* If it can be navigated to, create the link. */}
              {canNavigate && canNavigateToPage(firstPage)
                ? modulePageLink
                : module.label}
            </StepLabel>
            {/* If it is not a single page, create the sub pages in the stepper */}
            {!singlePage && (
              <StepContent
                className={
                  index === flowModules.length - 1
                    ? classes.endStepContent
                    : classes.stepContent
                }
              >
                <List>
                  {displayablePages.map((page) => {
                    const isCurrentPage = page.route === location.pathname;
                    const pageLink = createPageLink(
                      isCurrentPage,
                      page.label,
                      page.route,
                      classes.stepPageLinkActive,
                    );
                    const stepContent = (
                      <ListItem key={page.label}>
                        {canNavigate && canNavigateToPage(page)
                          ? pageLink
                          : page.label}
                      </ListItem>
                    );
                    if (isCurrentPage) {
                      /* if current page, then set the navigation to false so other sub-pages further
                      in the flow cannot be navigated to until the required page completion. */
                      canNavigate = false;
                    }
                    return stepContent;
                  })}
                </List>
              </StepContent>
            )}
          </Step>
        );
        // If the current module is a page, all modules past this module are not accessible by the user
        if (isCurrentModulePage) {
          canNavigate = false;
        }
        return step;
      })
      .filter((step) => step);
  }, [
    canNavigateToPage,
    classes.endStepContent,
    classes.stepContent,
    classes.stepNavigableLink,
    classes.stepPage,
    classes.stepPageLink,
    classes.stepPageLinkActive,
    classes.stepPageNavigableLink,
    flowModules,
    location.pathname,
  ]);

  return (
    <CoreStepper
      activeStep={activeModule}
      connector={<DottedConnector />}
      orientation="vertical"
      className={classes.root}
    >
      {steps}
    </CoreStepper>
  );
};

export default Stepper;
